@import '../../../styles/settings';
@import '../../../styles/ci/mixins';

.sidebar {
    flex: 0 0 auto;
    max-width: 100%;
    &.account {
        padding-left: 0;
    }
    .label-title {
        font-family: $work-sans;
        margin: rem-calc(0 0 8);
    }
    select {
        margin-top: 0;
    }
    .account-nav {
        margin-bottom: 2rem;
        ul {
            margin-bottom: 0;
        }
        .acc-nav {
            &-link {
                display: block;
                padding: rem-calc(13 8 13 21);
                font-weight: 600;
                border: 0;
                color: $dark-gray;
                font-size: rem-calc(17);
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:hover {
                    text-decoration: none;
                }
                &:focus {
                    outline-color: transparent;
                    text-decoration: none;
                    box-shadow:  0px 0px 0 2px $white, 0px 0px 4px 4px lighten($button-background, 5%);
                }
            }
            &-icon {
                margin-right: rem-calc(8);
            }
        }
    }
}

.acc-nav-item {
    margin: 0 0 0.5rem;
    &:hover {
        background: get-color(secondary);
        .acc-nav-link {
            color: $white;
        }
    }
    &.current {
        background: get-color(secondary);
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        z-index: 3;
        .acc-nav-link {
            color: $white;
            &:hover{
                color: rgba($white, 0.75);
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -20%;
            width: 72.5%;
            height: 120%;
            transform: skewX(-45deg);
            background-color: #c0112e;
            z-index: -1;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .sidebar {
        padding-right: 2rem;
    }
}

