.account-info-wrapper {
  padding: 0 1rem;
}

.dashboard-header {
  margin-top: 1rem;
}
.dashboard-header .header-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.7142857143rem;
  font-family: "factoria", "open-sans", Georgia, serif;
}

.account-info .title {
  display: block;
  font-weight: 700;
  font-size: 1.1428571429rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #3D3B3B;
}
.account-info .title.order {
  display: flex;
  justify-content: space-between;
}
.account-info .button {
  margin-top: 1.5rem;
  font-size: 0.9285714286rem;
}

.dashboard-content .wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.dashboard-content .wrapper .dashboard-card {
  flex: 0 0 100%;
  max-width: 100%;
}

.dashboard-card {
  background-color: #fafafa;
  padding: 0;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0;
  border: 1px solid #cccccc;
  margin-bottom: 2rem;
}

.block-title, .block-title span {
  background-color: #3D3B3B;
  color: #ffffff;
  padding: 0.75rem 1rem;
  font-size: 1.1428571429rem;
  font-family: "open-sans", "Segoe UI", Arial, Tahoma, sans-serif;
  font-weight: 700;
}

.block-content {
  padding: 1.5rem 1rem 2rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 48em) {
  .dashboard-header {
    margin-top: 2.5rem;
  }
  .account-info-wrapper {
    margin-top: 4rem;
    background: #f7f7f7;
    border-top: 5px solid #cccccc;
    padding: 0 1rem 3rem 0;
  }
  .block-content {
    padding: 2rem 2rem 2.5rem;
  }
}
@media screen and (min-width: 64em) {
  .dashboard-content .wrapper .dashboard-card {
    max-width: calc(50% - 1rem);
  }
  .dashboard-content .wrapper .dashboard-card.expanded {
    max-width: 100%;
  }
  .account-info .title.order {
    justify-content: flex-start;
  }
  .account-info .title.order .view-all {
    margin-left: 2rem;
  }
}
.sidebar {
  flex: 0 0 auto;
  max-width: 100%;
}
.sidebar.account {
  padding-left: 0;
}
.sidebar .label-title {
  font-family: "factoria", "open-sans", Georgia, serif;
  margin: 0 0 0.5714285714rem;
}
.sidebar select {
  margin-top: 0;
}
.sidebar .account-nav {
  margin-bottom: 2rem;
}
.sidebar .account-nav ul {
  margin-bottom: 0;
}
.sidebar .account-nav .acc-nav-link {
  display: block;
  padding: 0.9285714286rem 0.5714285714rem 0.9285714286rem 1.5rem;
  font-weight: 600;
  border: 0;
  color: #3D3B3B;
  font-size: 1.2142857143rem;
}
.sidebar .account-nav .acc-nav-link span {
  display: inline-block;
  vertical-align: middle;
}
.sidebar .account-nav .acc-nav-link:hover {
  text-decoration: none;
}
.sidebar .account-nav .acc-nav-link:focus {
  outline-color: transparent;
  text-decoration: none;
  box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 4px 4px #1e7eb0;
}
.sidebar .account-nav .acc-nav-icon {
  margin-right: 0.5714285714rem;
}

.acc-nav-item {
  margin: 0 0 0.5rem;
}
.acc-nav-item:hover {
  background: #EC2748;
}
.acc-nav-item:hover .acc-nav-link {
  color: #ffffff;
}
.acc-nav-item.current {
  background: #EC2748;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  z-index: 3;
}
.acc-nav-item.current .acc-nav-link {
  color: #ffffff;
}
.acc-nav-item.current .acc-nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.acc-nav-item.current:after {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  width: 72.5%;
  height: 120%;
  transform: skewX(-45deg);
  background-color: #c0112e;
  z-index: -1;
}

@media screen and (min-width: 48em) {
  .sidebar {
    padding-right: 2rem;
  }
}
.form-wrapper {
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
  display: inline-block;
}

@media screen and (min-width: 64em) {
  .form-wrapper {
    padding: 2.5rem 3rem 3rem;
  }
}