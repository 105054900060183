@import '../../../styles/settings';
@import '../../../styles/cas/mixins';

@import '../../../Magento_Customer/styles/css/dashboard-base-style';
@import '../../../Magento_Customer/styles/css/dashboard-nav';

$svg-icons-path: '../../images/icons/';

.form-wrapper {
    box-shadow: $global-box-shadow;
    display: inline-block;
}

@media screen and #{breakpoint(large)} {
    .form-wrapper {
        padding: 2.5rem 3rem 3rem;
    }
}
