@import '../../../styles/settings';
@import '../../../styles/ci/mixins';

$icon-url-path: '../#{$svg-icons-path}' !default;

.account-info-wrapper {
    padding: 0 1rem;
}

.dashboard-header {
    margin-top: 1rem;
    .header-title {
        margin-top: 0;
        margin-bottom: 1.5rem;
        font-size: rem-calc(24);
        font-family: $sec-font;
    }
}

.account-info {
    .title {
        display: block;
        font-weight: 700;
        font-size: rem-calc(16);
        margin-bottom: 0.75rem;
        padding-bottom: 0.25rem;
        border-bottom: 2px solid $dark-gray;
        &.order {
            display: flex;
            justify-content: space-between;
        }
    }
    .button {
        margin-top: 1.5rem;
        font-size: rem-calc(13);
    }
}

.dashboard-content {
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        .dashboard-card {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.dashboard-card {
    @include box-wrapper(lighten($off-white, 1%), 0);
    border: $global-border;
    margin-bottom: 2rem;
}

.block-title, .block-title span {
    background-color: $dark-gray;
    color: $white;
    padding: 0.75rem 1rem;
    font-size: rem-calc(16);
    font-family: $body-font-family;
    font-weight: 700;
}

.block-content {
    padding: 1.5rem 1rem 2rem;
    margin-bottom: .5rem;
}

@media screen and #{breakpoint(medium)} {
    .dashboard-header {
        margin-top: 2.5rem;
    }
    .account-info-wrapper {
        margin-top: 4rem;
        background: $off-white;
        border-top: 5px solid $global-border-color;
        padding: 0 1rem 3rem 0;
    }
    .block-content {
        padding: 2rem 2rem 2.5rem;
    }
}

@media screen and #{breakpoint(large)} {
    .dashboard-content {
        .wrapper {
            .dashboard-card {
                max-width: calc(50% - 1rem);
                &.expanded {
                    max-width: 100%;
                }
            }
        }
    }
    .account-info {
        .title.order {
            justify-content: flex-start;
            .view-all {
                margin-left: 2rem;
            }
        }
    }
}
